import * as React from 'react';
import styled from 'styled-components';
import { IndexLayout } from '../layouts';
import { NavBar } from '../components/segments/NavBar';
import { Helmet } from 'react-helmet';
import { theme } from '../utils/theme';
import DarkFooter from '../components/segments/DarkFooter';
import { NewAppText, NewAppTitle } from '../components/ui';
import { Link } from 'gatsby';
import { mobileSize } from '../utils';

export default () => {
  return (
    <NewIndexLayout>
      <Helmet>
        <meta name="title" content={'Terms of Service'} />
        <title>{'Rapptr Labs - Terms of Service'}</title>
        <meta name="description" content={'Terms of Service'} />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <DocContainer>
        <HeaderText>Terms of Service</HeaderText>
        <Wrapper>
          <TermsHeading>RAPPTR LABS</TermsHeading>
          <TermsHeading style={{ marginTop: '0px' }}>TERMS OF SERVICE</TermsHeading>
          <TermsSubheading>Last updated: March 13th, 2022</TermsSubheading>
          <TermsText>
            D & A Technologies LLC d/b/a Rapptr Labs (“<b>Rapptr Labs</b>,” “<b>we</b>,” “<b>our</b>
            ,” or “<b>us</b>”) makes this website and all content and materials related to this
            website (collectively, our “<b>Site</b>”) available to you (“<b>User</b>”, “<b>you</b>”,
            or “<b>your</b>”) for your use subject to the terms and conditions in this Terms of
            Service Agreement (the “<b>Agreement</b>”). By accessing or using our Site in any way,
            you agree to be bound by this Agreement. If you do not accept any of the terms of this
            Agreement and/or you do not meet or comply with its provisions, you may not use our
            Site.
          </TermsText>
          <TermsText style={{ textTransform: 'uppercase' }}>
            <b>IMPORTANT NOTICE:</b> YOUR USE OF OUR SITE IS SUBJECT TO AN ARBITRATION PROVISION IN
            SECTION 7 OF THIS AGREEMENT, REQUIRING ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING
            ARBITRATION. PLEASE CAREFULLY REVIEW SECTION 7 OF THIS AGREEMENT FOR MORE INFORMATION.
          </TermsText>
          <TermsListContainer>
            <ol>
              <li>
                <TermsListHeading>SITE</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Rapptr Labs’ Rights in and to Rapptr Labs Materials.</b> This Site, our
                      systems, our databases, and any and all other information, data, documents,
                      materials, works and other content, devices, methods, processes, hardware,
                      software and other technologies and inventions, including any technical or
                      functional descriptions, requirements, plans, specifications, or reports, that
                      are provided or used by Rapptr Labs or any subcontractor engaged by Rapptr
                      Labs in connection with this Site or otherwise comprised or related to this
                      Site, the designs of each of the foregoing, and any and all intellectual
                      property rights in the foregoing (collectively, the “
                      <b>Rapptr Labs Materials</b>”) shall at all times remain the exclusive
                      property of Rapptr Labs and its third-party licensors. Any third-party
                      software included in this Site is licensed subject to the additional terms of
                      the applicable third- party license.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Grant of License</b>. On the condition that you comply with all of your
                      obligations under this Agreement and subject to additional terms of any
                      third-party licenses applicable to our Site, we hereby grant to you a limited,
                      revocable, non-exclusive, non-transferable, non-sublicensable right to access
                      and use our Site solely for your own, non-commercial personal development
                      purposes. We reserve all rights not otherwise expressly granted by this
                      Agreement. If you do not comply with this Agreement, we reserve the right to
                      revoke any license granted in this Agreement and limit your access to our
                      Site. Any use of our Site that exceeds the rights expressly granted in this
                      Agreement is strictly prohibited and constitutes a violation of this
                      Agreement, which may result in the termination of your right to access and use
                      our Site. You are not acquiring any rights in or to the Rapptr Labs Materials
                      other than a non-exclusive right to access and use this Site solely in
                      accordance with the terms of this Agreement.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Modification</b>. We may discontinue or alter any aspect of our Site,
                      restrict the time our Site or website is available, and restrict the amount of
                      use permitted at our sole discretion and without prior notice or liability to
                      you. We may also install bug fixes, updates, patches, and other upgrades to
                      our Site without prior notice or liability to you. Your only remedy is to
                      discontinue using our Site if you do not want a modification we make to our
                      Site.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Removal of Access</b>. Your access to our Site is provided on a temporary
                      basis with no guarantee of future availability or continued right to access.
                      You agree that we may immediately suspend or terminate your access to our Site
                      or any part thereof. Cause for such measures include, without limitation: (1)
                      breach or violation of this Agreement or other incorporated agreements or
                      guidelines; (2) discontinuance or material modification to our Site; (3)
                      unexpected technical or security issues or problems; (4) extended periods of
                      inactivity; or (5) your engagement in fraudulent or illegal activities. You
                      further agree that such measures may be taken in our sole discretion and
                      without liability to you or any third party.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Defects and Availability</b>. We use commercially reasonable efforts to
                      maintain our Site, but we are not responsible for any defects or failures
                      associated with our Site, any part thereof, or any damages (such as lost
                      income, opportunities or any other consequential or indirect damages) that may
                      result from any such defects or failures. Our Site may be inaccessible or
                      inoperable for any reason, including, without limitation: (1) equipment
                      malfunctions; (2) periodic maintenance procedures or repairs which we may
                      undertake from time to time; or (3) causes beyond our reasonable control or
                      which we could not reasonably foresee. You understand that our Site is
                      provided over the Internet, so the quality and availability of our Site may be
                      affected by factors outside of our control. Our Site is not intended to be
                      available 100% of the time and we do not make any representations, warranties,
                      or guarantees regarding the reliability or availability of our Site. We do not
                      represent, warrant, or guarantee that our Site will always be available or are
                      completely free of human or technological errors. We will not be liable to you
                      or any third party for damages or losses related to our Site being
                      unavailable.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Restrictions</b>. You may not: (1) license, sublicense, sell, resell,
                      transfer, assign, distribute, or otherwise commercially exploit or make
                      available to any third party any portion of our Site in any way; (2) copy,
                      modify, adapt, alter, translate, create derivative works, reverse engineer,
                      decompile, disassemble, or otherwise attempt to learn the source code,
                      structure, or ideas upon which our Site is based; (3) use our Site or Rapptr
                      Labs Materials to develop a competing service or product; (4) use any device,
                      software, or routine intended to damage or otherwise interfere with the proper
                      functioning of our Site, servers, or networks connected to our Site or take
                      any other action that interferes with any other person’s use of our Site; (5)
                      decrypt, transfer, create Internet links to our Site, or “frame” or “mirror”
                      our Site on any other server or wireless or Internet-based device; (6) use or
                      merge our Site or any component thereof with other software, databases, or
                      services not provided or approved by us; (7) circumvent or attempt to
                      circumvent any electronic protection measures in place to regulate or control
                      access to our Site; (8) use our Site for unlawful purposes; (9) develop,
                      distribute, or sell any software or other functionality capable of launching,
                      being launched from, or otherwise integrated with our Site; (10) use any bot,
                      spider, or other automatic or manual device or process for the purpose of
                      harvesting or compiling information on our Site for any reason; (11) access or
                      attempt to access any other user’s account; (12) use any Rapptr Labs Materials
                      made available through our Site in any manner that misappropriates any trade
                      secret or infringes any copyright, trademark, patent, rights of publicity, or
                      other proprietary right of any party; (13) introduce or attempt to introduce
                      into our Site any virus, rogue program, Trojan horse, worm or other malicious
                      or intentionally destructive code, software routines, or equipment components
                      designed to permit unauthorized access to or disable, erase, or otherwise harm
                      our Site, or perform any such actions; (14) introduce or attempt to introduce
                      into our Site any back door, time bomb, drop dead device, or other software
                      routine designed to disable a computer program automatically with the passage
                      of time or under the positive control of an unauthorized person; (15) delete,
                      modify, hack, or attempt to change or alter our Site, Rapptr Labs Materials,
                      or notices on our Site; (16) connect to or access any Rapptr Labs computer
                      system or network other than our Site; or (17) impersonate any other person or
                      entity to use or gain access to our Site.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Prosecution</b>. We reserve the right to investigate and prosecute
                      violations of any of the above to the fullest extent of the law. We may
                      involve and cooperate with law enforcement authorities in prosecuting users
                      who violate this Agreement.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>CONTENT AND DATA ON OUR SITE</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Rapptr Labs Content</b>. Our Site may include or provide access to
                      information, software, photos, videos, text, graphics, music, sounds, and
                      other material or information provided by us or third parties (collectively,
                      the “<b>Rapptr Labs Content</b>”) that are protected by copyrights, patents,
                      trademarks, trade secrets, or other intellectual property laws. You are not
                      acquiring any rights in or to the Rapptr Labs Content other than a non-
                      exclusive right to access and use the Rapptr Labs Content solely in accordance
                      with the terms of this Agreement and solely in connection with your use of our
                      Site. You understand and agree that Rapptr Labs’ rights in any Rapptr Labs
                      Content are valid and protected in all forms, media, and technologies existing
                      now or developed in the future. You may not obscure or remove any proprietary
                      rights notices contained in or on the Rapptr Labs Content.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>User Content License</b>. Our Site includes features that allow you to
                      upload, submit, download, store, or transmit data, information including
                      personal information such as your name or contact details, or other content
                      through our Site (collectively, “<b>User Content</b>”). By submitting User
                      Content to our Site, you grant Rapptr Labs a transferable, nonexclusive,
                      worldwide, perpetual, irrevocable, royalty-free right and license to use,
                      reproduce, modify, edit, adapt, publish, translate, display, distribute,
                      sublicense through multiple tiers, dispose of, assign, create derivative works
                      of, and create compilations incorporating User Content for the purposes of:
                      (i) providing our Site, (ii) providing our products or services, and (iii)
                      carrying out other lawful business purposes. We reserve the right to remove
                      User Content from our Site at any time and for any reason without notification
                      to you. YOU AGREE THAT YOU WILL EVALUATE AND BEAR ALL RISK RELATED TO THE USE
                      OF, OR ANY ACTIVITIES ASSOCIATED WITH, USER CONTENT THAT YOU POST OR PROVIDE
                      THROUGH OUR SITE. THE RESULTS OF ANY ACTIONS YOU TAKE BASED ON USER CONTENT,
                      RAPPTR LABS CONTENT AND MATERIALS, OR OTHER CONTENT YOU FIND ON OUR SITE ARE
                      SOLELY YOUR RESPONSIBILITY. UNDER NO CIRCUMSTANCES WILL WE BE LIABLE IN ANY
                      WAY FOR USER CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                      RESULT OF ANY USER CONTENT PROCESSED THROUGH OUR SITE.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>User Content Restrictions</b>. You may not upload, post, or transmit any
                      User Content that: (1) would violate or infringe the proprietary, privacy,
                      publicity, or intellectual property rights of Rapptr Labs, our customers, or
                      any third party; (2) is obscene, defamatory, threatening, harassing, abusive,
                      libelous, hateful, or harmful to any other person or entity; (3) violates any
                      applicable law, statute, ordinance, or regulation; (4) puts in jeopardy the
                      security of your account, Rapptr Labs, our customers, or our Site; or (5)
                      promotes or displays any of the following content: (a) pornography; (b)
                      violence; (c) racial intolerance or advocacy against any individual, group, or
                      organization; (d) profanity; or (e) illicit drugs and drug paraphernalia. We
                      reserve the right, but have no obligation, to pre-screen, review, flag,
                      filter, modify, refuse, and remove any and all User Content from the Site. You
                      understand and expressly acknowledge that by using our Site you may be exposed
                      to content that you find offensive, indecent, or objectionable and that we
                      will not be liable to you or any other person or entity for your consumption
                      of any content on our Site.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Feedback</b>. We welcome your comments, feedback, information, or materials
                      regarding our Site or any of our other products or services (collectively, “
                      <b>Feedback</b>”). Your Feedback will become our property upon your submission
                      to us. By submitting your Feedback to us, you agree to assign, and hereby
                      irrevocably assign to us, all right, title, and interest in and to the
                      Feedback and all copyrights and other intellectual property rights embodied in
                      such Feedback on a worldwide basis. We will be free to use, copy, distribute,
                      publish and modify your Feedback on an unrestricted basis, without
                      compensation to you. Moreover, you hereby assign or waive, as the case may be,
                      any moral rights that you may have in or to the Feedback.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Links; Third Party Materials</b>. Our Site may include links to other
                      websites or resources on the Internet or utilize content of our customers or
                      other third parties (collectively, “<b>Third Party Materials</b>”). Because we
                      have no control over Third Party Materials or the administration of Third
                      Party Materials by the third parties that provide them, you acknowledge and
                      agree that we are not responsible for the availability of such materials, and
                      we do not endorse and are not responsible or liable for any content,
                      advertising, products, or other materials on or available from such Third
                      Party Materials or for any privacy or other practices of the third parties
                      operating those websites or providing such materials. You further acknowledge
                      and agree that we will not be responsible or liable, directly or indirectly,
                      for any damage or loss caused or alleged to be caused by, or in connection
                      with or resulting from your use of or reliance on, any such Third Party
                      Materials available on or through any such website or resource. We strongly
                      encourage you to review any separate terms of use and privacy policies
                      governing use of such third party websites and Third Party Materials.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Ownership of Intellectual Property</b>. Rapptr Labs or its licensors
                      exclusively own all right, title and interest in and to our Site, Rapptr Labs
                      Content, Rapptr Labs Materials, and Feedback, including but not limited to,
                      all ideas, inventions, inferences, discoveries, source and object software
                      code, developments, derivative works, enhancements, upgrades, fixes and
                      patches, formats and processes, and all images, trademarks, service marks,
                      logos and icons displayed or related therein or thereto (collectively, “
                      <b>Rapptr Labs IP</b>”). Except as expressly provided herein, you have no
                      right, license, or authorization with respect to any of the Rapptr Labs IP.
                      You shall not assert any claims to the contrary or otherwise do anything
                      inconsistent with the allocation of ownership herein, including, but not
                      limited to, challenging the validity of the authorizations or any intellectual
                      property rights granted herein. In the event you are ever deemed to be the
                      owner of any of the Rapptr Labs IP, you shall immediately take all necessary
                      steps to evidence, transfer, perfect, vest, or confirm Rapptr Labs’ right,
                      title and interest in the Rapptr Labs IP. Rapptr Labs is not transferring or
                      granting to you any right, title, or interest in or to (or granting you any
                      license or other permissions in or to) any Rapptr Labs IP. The sole exception
                      of the foregoing reservation of rights are the limited rights granted to you
                      to use our Site, which shall automatically terminate upon expiration or
                      termination of this Agreement. Any unauthorized use of any Rapptr Labs IP,
                      whether owned by us or other parties, may violate copyright laws, trademark
                      laws, privacy and publicity laws and communications regulations and statutes.
                      Trademarks owned by third parties are the property of those respective third
                      parties.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>ACCESS TO OUR SITE</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Eligibility</b>.{' '}
                      <b>YOU MUST BE AT LEAST AGE EIGHTEEN (18) TO USE OUR SITE</b>. BY ACCESSING,
                      USING AND/OR SUBMITTING INFORMATION TO OR THROUGH OUR SITE, YOU REPRESENT THAT
                      YOU ARE NOT YOUNGER THAN AGE 18.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Access</b>. Access to our Site may require you to submit information, such
                      as your name and contact information, through an online portal. This portal
                      will require you to provide certain requested information (including personal
                      information) that may constitute User Content hereunder. For more information
                      about our collection of personal data about you in connection your use of our
                      Site, please review our Privacy Notice{' '}
                      <StyledLink to="/privacy">here</StyledLink>.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Unauthorized Use</b>. You must immediately notify us if you learn of or
                      have reason to suspect any unauthorized use of your information or any other
                      breach of security. You also agree that you will provide truthful and accurate
                      information to us. We may refuse to grant you access to our Site for any
                      reason, including, without limitation, if we have reason to believe that you
                      are impersonating someone else, that the information you provide is protected
                      by trademark or other proprietary rights, or is vulgar or otherwise offensive.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Storing Information</b>. Our Site may allow you to store the information
                      you provide when you access our Site.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Mobile Use</b>. Your contract with your mobile network provider (“
                      <b>Mobile Provider</b>”) will continue to apply when accessing or using our
                      Site on your mobile, handheld device (“<b>Mobile Device</b>”). You understand
                      that your Mobile Provider may charge you fees for your use of its network
                      connection services while accessing or using our Site, for data downloading,
                      e-mail, text messages, for roaming, and other Mobile Provider or third party
                      charges. YOU ACCEPT RESPONSIBILITY FOR ALL MOBILE PROVIDER FEES. Please review
                      our Privacy Statement for more information on how we may administer SMS text
                      messaging, including details on how to opt-out.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>REPRESENTATIONS</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Representations</b>. You hereby represent and warrant that: (1) you (a) are
                      at least the age of 18; and (b) have the power and authority to enter into and
                      perform your obligations under this Agreement; (2) all information provided by
                      you to us is truthful, accurate and complete; (3) you will comply with the
                      terms and conditions of this Agreement and any other agreement to which you
                      are subject that is related to your use of our Site, your Feedback, User
                      Content, or any part thereof; (4) if applicable, you have provided and will
                      maintain accurate and complete information with us, including, without
                      limitation, your legal name, email address, and any other information we may
                      reasonably require; (5) your access to and use of our Site or any part thereof
                      will not constitute a breach or violation of any other agreement, contract,
                      terms of use or any law or regulation to which you are subject; (6) you will
                      immediately notify us in the event that you learn or suspect that the contact
                      information you provided to us has been disclosed or otherwise made known to
                      any other person; (7) you will not use our Site in order to gain competitive
                      intelligence about us, our Site, or any product or service offered via our
                      Site or to otherwise compete with us; and (8) your User Content does not
                      violate the rights of any third party, including, without limitation, the
                      intellectual property, privacy or publicity rights of any third party, and
                      constitutes an original work of authorship by you.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Systems Representations</b>. You represent that you shall, at all times
                      during the term, procure, set up, maintain, and operate in good repair, at
                      your sole cost and expense, all equipment, resources, technology, or systems,
                      including computer hardware and equipment, any Internet access, third party
                      software, and telecommunications service, necessary for you to access and use
                      our Site (collectively, the “<b>User Systems</b>”). You further represent that
                      in connection with your User Systems, you shall employ all physical,
                      administrative, and technical controls, screening and security procedures, and
                      other safeguards necessary to securely administer the distribution and use of
                      our Site and Rapptr Labs Content and Materials and protect against any
                      unauthorized access to or use of our Site.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Feedback Representations</b>. In the event you provide any Feedback via our
                      Site, you hereby make the following additional representations and warranties
                      to us: (1) you are owner of such Feedback or otherwise have the right to grant
                      us the licenses or assignments granted pursuant to this Agreement; (2) you
                      have secured any and all consents necessary to provide the Feedback and to
                      grant the foregoing licenses or assignments; (3) the Feedback does not violate
                      the rights of any third party, including, without limitation, the intellectual
                      property, privacy or publicity rights of any third party, and such Feedback
                      does not contain any personally identifiable information about third parties
                      in violation of such parties’ rights; (4) the use of any Feedback will not
                      result in harm or personal injury to any third party; and (5) all factual
                      information contained in the Feedback is true and accurate.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>DISCLAIMERS OF WARRANTY</TermsListHeading>
              </li>
              <TermsText>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR SITE IS PROVIDED “AS IS” AND “AS
                AVAILABLE,” AND AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE
                EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT. WITHOUT LIMITATION, WE
                MAKE NO WARRANTY THAT OUR SITE (INCLUDING THE RAPPTR LABS CONTENT AND MATERIALS)
                WILL MEET YOUR REQUIREMENTS, THAT USE OF THE FOREGOING WILL BE UNINTERRUPTED,
                TIMELY, SECURE, OR ERROR-FREE, THAT USING RAPPTR LABS’ SITE, PRODUCTS, OR SERVICES
                WILL RESULT IN ANY CERTAIN RESULTS, THAT THE RESULTS OBTAINED FROM THE USE OF THE
                FOREGOING OR ANY INFORMATION OR CONTENT FOUND ON OUR SITE WILL BE ACCURATE OR
                RELIABLE, THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF OUR SITE WILL BE
                CORRECTED, THAT OUR SITE AND ANY CONTENT OR INFORMATION FOUND ON OUR SITE WILL BE
                VIRUS-FREE, OR THAT THE QUALITY OF ANY INFORMATION, CONTENT, OR OTHER MATERIALS
                OBTAINED THROUGH OUR SITE WILL MEET YOUR EXPECTATIONS. RAPPTR LABS WILL HAVE NO
                LIABILITY REGARDING ANY LOSS OF DATA. ANY CONTENT OR OTHER MATERIALS DOWNLOADED OR
                OTHERWISE OBTAINED THROUGH THE USE OF OUR SITE IS DONE AT YOUR SOLE RISK, AND YOU
                WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
                MATERIAL. FURTHER, PLEASE NOTE THAT NO ADVICE OR INFORMATION, OBTAINED BY YOU
                THROUGH OUR SITE OR ANY PRODUCT WILL CREATE ANY WARRANTY NOT EXPRESSLY PROVIDED FOR
                IN THIS AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                WARRANTIES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
              </TermsText>
              <li>
                <TermsListHeading>INDEMNITY; LIMITATION OF LIABILITY</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Indemnity</b>. YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS RAPPTR
                      LABS, OUR AFFILIATES, OUR AGENTS AND REPRESENTATIVES, AND ALL OF THEIR
                      OFFICERS, MEMBERS, MANAGERS, AND EMPLOYEES (COLLECTIVELY, “
                      <b>RELEASED PARTIES</b>”) FROM AND AGAINST ANY AND ALL CLAIMS, LIABILITIES,
                      DAMAGES, LOSSES, DEMANDS, OR EXPENSES, INCLUDING ATTORNEY’S FEES AND COSTS AND
                      EXPENSES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH, WITHOUT LIMITATION: (A)
                      YOUR USE OF OUR SITE, (B) YOUR VIOLATION OF THIS AGREEMENT, (C) ANY USER
                      CONTENT YOU PROVIDE THROUGH OUR SITE, (D) YOUR VIOLATION OF ANY LAW OR THE
                      RIGHTS OF ANY THIRD PARTY, AND (E) YOUR NEGLIGENCE OR WILLFUL MISCONDUCT.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Limitation of Liability</b>. UNDER NO CIRCUMSTANCES SHALL RELEASED PARTIES
                      BE LIABLE TO YOU OR ANY THIRD PARTY WITH RESPECT TO ITS OBLIGATIONS UNDER THIS
                      AGREEMENT OR OTHERWISE FOR LOST PROFITS, LOSS OF DATA, WORK STOPPAGE, PERSONAL
                      INJURY, DEATH, OR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INDIRECT, INCIDENTAL OR
                      PUNITIVE DAMAGES, EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                      DAMAGES. IF YOU ARE DISSATISFIED WITH OUR SITE, OR WITH THIS AGREEMENT, YOUR
                      SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING OUR SITE. IN ANY EVENT, THE
                      TOTAL MAXIMUM AGGREGATE LIABILITY FOR RELEASED PARTIES UNDER THIS AGREEMENT
                      FOR THE USE OF ANY OR ALL PARTS OF OUR SITE IN ANY MANNER WHATSOEVER SHALL BE
                      LIMITED TO ONE HUNDRED DOLLARS ($100.00 USD).
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>For California Residents</b>. IF YOU ARE A CALIFORNIA RESIDENT OR COULD
                      OTHERWISE CLAIM THE PROTECTIONS OF CALIFORNIA LAW, YOU FURTHER EXPRESSLY WAIVE
                      THE PROVISIONS OF SECTION 1542 OF THE CALIFORNIA CIVIL CODE, WHICH READS AS
                      FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO THE CLAIMS WHICH THE CREDITOR
                      DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
                      THE RELEASE WHICH, IF KNOWN BY HIM OR HER, MUST HAVE MATERIALLY AFFECTED HIS
                      OR HER SETTLEMENT WITH THE DEBTOR." YOU ACKNOWLEDGE THAT YOU HAVE READ AND
                      UNDERSTAND SECTION 1542 OF THE CALIFORNIA CIVIL CODE, AND YOU HEREBY EXPRESSLY
                      WAIVE AND RELINQUISH ALL RIGHTS AND BENEFITS UNDER THAT SECTION AND ANY LAW OF
                      ANY JURISDICTION OF SIMILAR EFFECT WITH RESPECT TO YOUR RELEASE OF ANY CLAIMS
                      YOU MAY HAVE AGAINST RELEASED PARTIES.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>
                  DISPUTE RESOLUTION AND GOVERNING LAW, JURISDICTION AND COSTS
                </TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Governing Law</b>. This Agreement will be governed by and construed,
                      interpreted, and enforced in accordance with the laws of the State of New
                      Jersey without reference to its conflicts or choice of law principles. Any
                      arbitration or court proceeding will take place in the State of New Jersey and
                      you hereby consent to the exclusive jurisdiction and venue of the state or
                      Federal courts in the county of Hudson County, New Jersey. You irrevocably
                      submit and consent to the personal jurisdiction of such courts.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Dispute Resolution</b>. To the extent feasible, the parties desire to
                      resolve any dispute, claim or controversy arising out of or relating to your
                      use of or access to our Site or Rapptr Labs Content and Materials, this
                      Agreement or the breach, termination, enforcement, interpretation, or validity
                      of this Agreement, including the determination of the scope or applicability
                      of this agreement to arbitration (a “<b>Dispute</b>”) through discussions and
                      negotiations between each other. The parties agree to attempt to resolve any
                      Disputes by negotiation with the other party (by phone, electronic
                      correspondence, or written correspondence). If we are not able to resolve any
                      Dispute ourselves, you and Rapptr Labs agree to resolve such Dispute through
                      confidential binding arbitration as set forth below.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Binding Arbitration</b>. If you and Rapptr Labs are unable to resolve a
                      Dispute through informal negotiations, either you or Rapptr Labs may elect to
                      have the Dispute (except those Disputes expressly excluded below) finally and
                      exclusively resolved by binding arbitration. Any election to arbitrate by one
                      party shall be final and binding on the other. The arbitration shall be
                      commenced and conducted under the Commercial Arbitration Rules of the American
                      Arbitration Association (“<b>AAA</b>”) and, where appropriate, the AAA’s
                      Supplementary Procedures for Consumer Related Disputes (“
                      <b>AAA Consumer Rules</b>”) both of which are available at the AAA website,
                      www.adr.org. The determination of whether a Dispute is subject to arbitration
                      shall be governed by the Federal Arbitration Act and determined by a court
                      rather than an arbitrator. Your arbitration fees and your share of arbitrator
                      compensation shall be governed by the AAA Rules and, where appropriate,
                      limited by the AAA Consumer Rules. The arbitration may be conducted in person,
                      through the submission of documents, by phone or online. The arbitrator will
                      make a decision in writing but need not provide a statement of reasons unless
                      requested by a party. The arbitrator must follow applicable law, and any award
                      may be challenged if the arbitrator fails to do so. Except as otherwise
                      provided in this Agreement you and Rapptr Labs may litigate in court to compel
                      arbitration, stay proceedings pending arbitration, or to confirm, modify,
                      vacate or enter judgment on the award entered by the arbitrator.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Waiver of Rights</b>. You hereby agree that you understand the consequences
                      of agreeing to binding arbitration under this Section, including giving up any
                      constitutional rights to have the Dispute determined by a court of law or by a
                      jury and any right that you may have under Article 13 of the State Bar Act,
                      where applicable, to have a trial de novo by a court after nonbinding
                      arbitration of a dispute concerning fees or costs; that discovery of
                      information in arbitration may be limited; and that the arbitration decision
                      will be final and binding, except to the limited extent that judicial review
                      might be available. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS AGREEMENT
                      MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
                      BASIS OR THROUGH PARTICIPATION IN A CLASS ACTION OR REPRESENTATIVE ACTION.
                      CLAIMS OR DISPUTES OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED
                      JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.
                    </TermsListText>
                  </li>
                </ol>
              </li>
              <li>
                <TermsListHeading>MISCELLANEOUS</TermsListHeading>
                <ol>
                  <li>
                    <TermsListText>
                      <b>Term</b>. This Agreement is effective upon your acceptance and will
                      continue in full force until terminated by you or us. You may terminate this
                      Agreement at any time by immediately discontinuing all access to our Site.
                      Termination or cancellation of this Agreement will not affect any right or
                      relief to which we may be entitled at law or in equity. We reserve the right
                      to terminate this Agreement at any time and for any reason without prior
                      notice to you. Further, you agree that we will not be liable to you or any
                      third-party for any termination or suspension of your access to our Site or
                      any part thereof.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Independent Contractors</b>. You understand and expressly agree that you
                      and Rapptr Labs are independent contractors and not agents or employees of the
                      other party. Neither you nor Rapptr Labs has any right, power, or authority to
                      act or create any obligation, express or implied, on behalf of the other
                      party.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Consent to Do Business Electronically</b>. We use and rely upon electronic
                      records and electronic signatures for the execution and delivery of this
                      Agreement and any other agreements, undertakings, notices, disclosures or
                      other documents, communications or information of any type sent or received in
                      accordance with this Agreement and in performing our obligations and
                      exercising our rights under this Agreement. Neither you nor Rapptr Labs will
                      prevent or inhibit in any way the other party from printing, saving, or
                      otherwise storing electronic records sent or otherwise made available to the
                      other party. You agree not to contest the authorization for, or validity or
                      enforceability of, electronic records and electronic signatures, or the
                      admissibility of copies thereof, under any applicable law relating to whether
                      certain agreements, files, or electronic records are to be in writing or
                      signed by you to be bound thereby. You will bear your own costs and expenses
                      in conducting business electronically, and will undertake all steps necessary,
                      including software, hardware, and other equipment upgrades and purchases, in
                      order to be able to conduct business electronically.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Equitable Relief</b>. You agree that breach of the provisions of this
                      Agreement would cause irreparable harm and significant injury to us which
                      would be both difficult to ascertain and which would not be compensable by
                      damages alone. As such, you agree that we have the right to enforce the
                      provisions of this Agreement by injunction (without necessity of posting
                      bond), specific performance, or other equitable relief without prejudice to
                      any other rights and remedies we may have for your breach of this Agreement.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Privacy</b>. We collect, store, and use data collected from you in
                      accordance with our Privacy Notice, which can be found{' '}
                      <StyledLink to="/privacy">here</StyledLink>. The terms and conditions of our
                      Privacy Notice are hereby expressly incorporated into this Agreement.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Entire Agreement</b>. This Agreement and any hyperlinked policies and
                      procedures constitute the entire agreement between you and Rapptr Labs with
                      respect to the subject matter hereof and supersede all prior agreements, both
                      oral and written, with respect to the subject matter hereof. We may revise and
                      update this Agreement from time to time and will post the updated Agreement to
                      our Site. UNLESS OTHERWISE STATED IN THE AMENDED VERSION OF THIS AGREEMENT,
                      ANY CHANGES TO THIS AGREEMENT WILL APPLY IMMEDIATELY UPON POSTING. Although we
                      are not obligated to provide you with notice of any changes, any changes to
                      this Agreement will not apply retroactively to events that occurred prior to
                      such changes. Your continued use of our Site will constitute your agreement to
                      any new provisions within the revised Agreement.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Waiver; Severability</b>. Our failure to enforce any provision of this
                      Agreement will not be deemed to be a waiver of our right to enforce them. If
                      any term or provision of this Agreement will be held to be invalid, illegal,
                      or unenforceable, the remaining terms and provisions of this Agreement will
                      remain in full force and effect, and such invalid, illegal, or unenforceable
                      term or provision will be deemed not to be part of this Agreement.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Assignment</b>. You may not assign, transfer, or sell (voluntarily or by
                      operation of law) your rights or obligations under this Agreement, nor
                      delegate your duties hereunder to any other person, without our prior written
                      consent. Any purported assignment without our consent will be void and will
                      constitute a breach of this Agreement. We may assign this Agreement or
                      delegate or subcontract our obligations under this Agreement at any time.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Survival</b>. The provisions of this Agreement that by their content are
                      intended to survive the expiration or termination of this Agreement,
                      including, without limitation, provisions governing ownership and use of
                      intellectual property, representations, disclaimers, warranties, liability,
                      indemnification, governing law, jurisdiction, venue, remedies, rights after
                      termination, and interpretation of this Agreement, will survive the expiration
                      or termination of this Agreement for their full statutory period.
                    </TermsListText>
                  </li>
                  <li>
                    <TermsListText>
                      <b>Contact Us</b>. If you have questions, email{' '}
                      <a href="mailto: Info@RapptrLabs.com">Info@RapptrLabs.com</a>.
                    </TermsListText>
                  </li>
                </ol>
              </li>
            </ol>
          </TermsListContainer>
        </Wrapper>
      </DocContainer>
      <DarkFooter />
    </NewIndexLayout>
  );
};

const HeaderText = styled(NewAppTitle)`
  width: 100%;
  text-align: center;
  background-color: ${theme.BACKGROUND_COLOR};
  color: ${theme.WHITE_COLOR};
  font-weight: 700;
  font-size: 62px;
  padding-top: 77.5px;
  padding-bottom: 77.5px;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding-top: 50px;
  padding-bottom: 150px;
  @media ${mobileSize} {
    padding: 30px 2rem;
  }
`;

const TermsText = styled(NewAppText)`
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const TermsHeading = styled(NewAppText)`
  margin: 0;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-size: 20px;
`;

const TermsSubheading = styled(NewAppText)`
  margin: 1rem auto;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const TermsListContainer = styled.div`
  ol {
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0;
  }

  li:before {
    display: inline;
    content: counters(item, '.') '.';
    counter-increment: item;
    margin-right: 1rem;
  }

  ol > li:before {
    font-weight: bold;
  }
`;

const TermsListHeading = styled(NewAppText)`
  display: inline;
  margin-left: 1rem;
  font-weight: bold;
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
`;

const TermsListText = styled(NewAppText)`
  display: inline;
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
`;

const DocContainer = styled.div`
  background: ${theme.WHITE_COLOR};
  /* max-width: 900px; */
  width: 100%;
  padding: 30px auto;
  padding-top: 120px;

  @media ${mobileSize} {
    padding: 30px 2.5rem;
  }
`;

const NewIndexLayout = styled(IndexLayout)``;

const StyledLink = styled(Link)`
  cursor: pointer;
`;
